.ant-form-item-control-input-content path {
  stroke-width: 2px !important;
}

.ant-form-item-control-input-content  .ant-btn.ant-btn-sm {
  padding: 0px;
}

.ant-alert-message {
  font-weight: 600;
}
