.ant-select-item {
  border: 1px solid #fff;
  font-weight: 700;
}

.ant-select-item {
  border-radius: 12px !important;
  padding: 6px 10px !important;
}

.ant-select-selection-item-content {
  font-weight: 700;
  font-family: 'Montserrat';
  font-size: 12px;
}

.ant-select-selection-item {
  font-size: 14px;
}

.unit-select  {
  font-family: Vollkorn;
  font-size: 14px !important;
}

.unit-select span.ant-select-selection-placeholder {
  font-size: 14px !important;
}

.ant-select-selector {
  font-family: Vollkorn;
  font-size: 14px ;
}

.ant-empty-description {
  font-family: 'Montserrat';
  font-size: 12px;
}

.ant-modal-confirm-paragraph {
  margin: 0;
  max-width: none !important;
}

.ant-modal-confirm-content {
  text-align: justify;
  margin: 12px 0;
}

.ant-modal-confirm-content p {
  margin: 8px 0 0 0;
}

.ant-modal-content {
  padding: 30px 70px !important;
}

.ant-modal-confirm-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.ant-modal-confirm-btns button {
    margin: 0 !important;
    width: 100%;
}

.ant-modal-confirm-body.ant-modal-confirm-body-has-title svg {
  position: absolute;
  left: 25px;
  top: 29px;
}

.ant-form-item-explain-error {
  margin-top: 9px;
  line-height: 1.2em;
}

.ant-form-item-explain-error::-moz-selection {
  background: #ffc7be !important;
}

.ant-form-item-explain-error::selection {
  background: #ffc7be !important;
}

.ant-form-item-explain-error {
  color: #ff4d4f;
}

.minus {
  padding-bottom: 1px
}

.minus.iconify path {
  stroke-width: 4px !important;
}
